import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import './App.css';
import Introduction from './components/Introduction';

function App() {
  const [tallyCount, setTallyCount] = useState(0);
  const [showIntro, setShowIntro] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const tallyContainerRef = useRef(null);

  const tallyImages = [
    process.env.PUBLIC_URL + '/img/tally-one.svg',
    process.env.PUBLIC_URL + '/img/tally-two.svg',
    process.env.PUBLIC_URL + '/img/tally-three.svg',
    process.env.PUBLIC_URL + '/img/tally-four.svg',
    process.env.PUBLIC_URL + '/img/tally-five.svg' // Always use this for the fifth tally
  ];

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    };

    Promise.all(tallyImages.map(loadImage))
      .then(() => setImagesLoaded(true))
      .catch((error) => console.error('Error preloading images:', error));
  }, []);

  useEffect(() => {
    document.body.style.backgroundImage = "url('/img/paper-background.jpg')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';
    document.body.style.backgroundRepeat = 'no-repeat';
  }, []);

  useEffect(() => {
    if (tallyContainerRef.current) {
      tallyContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [tallyCount]);

  const incrementTally = () => {
    setTallyCount(tallyCount + 1);
  };

  const decrementTally = () => {
    if (tallyCount > 0) {
      setTallyCount(tallyCount - 1);
    }
  };

  const resetTally = () => {
    setTallyCount(0);
  };

  const renderTallies = () => {
    const groups = [];
    for (let i = 0; i < Math.ceil(tallyCount / 5); i++) {
      const group = [];
      for (let j = 0; j < 5; j++) {
        const index = i * 5 + j;
        if (index < tallyCount) {
          const className = j === 4 ? 'tally-mark slash' : 'tally-mark';
          group.push(<img key={index} src={tallyImages[j]} alt={`tally-${j}`} className={className} />);
        }
      }
      groups.push(<div key={i} className="tally-group">{group}</div>);
    }
    return groups;
  };

  const handlers = useSwipeable({
    onSwipedLeft: decrementTally,
    onSwipedRight: incrementTally,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => { // eslint-disable-next-line
      switch (event.key) {
        case 'ArrowUp':
        case ' ': // Space bar
          setTallyCount(prevCount => prevCount + 1);
          break;
        case 'ArrowDown':
          setTallyCount(prevCount => Math.max(0, prevCount - 1));
          break;
        case 'Escape':
          setTallyCount(0);
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const startCounting = () => {
    setShowIntro(false);
  };

  const AdSpace = () => (
    <div className="ad-space">
      <p>Advertisement</p>
      {/* Replace this with actual ad content */}
    </div>
  );

  if (!imagesLoaded) {
    return <div>Loading...</div>; // Or a more sophisticated loading indicator
  }

  if (showIntro) {
    return <Introduction onStart={startCounting} />;
  }

  return (
    <div className="App" {...handlers}>
      <section className="App-section">
        <div className="tally-container content-width" ref={tallyContainerRef}>
          {renderTallies()}
        </div>
      </section>
      <footer className="App-footer">
        <div className="total-count">
          Total: {tallyCount}
        </div>
        <div className="button-container">
          <button onClick={incrementTally}>Up</button>
          <button onClick={decrementTally}>Down</button>
          <button onClick={resetTally} className="reset-button">Reset</button>
        </div>
        <div className="credit">
          Created by <a href="https://pixolomew.digital/" target="_blank" rel="noopener noreferrer">Pixolomew.digital</a>
        </div>
      </footer>
    </div>
  );
}

export default App;
