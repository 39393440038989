import React from 'react';

function Introduction({ onStart }) {
  const AdSpace = () => (
    <div className="ad-space">
      <p>Advertisement</p>
      {/* Replace this with actual ad content */}
    </div>
  );

  return (
    <div className="App">
      <div className="App-section">
        <div className="introduction content-width">
          <h1>TallyMaster</h1>
          <p>TallyMaster is a simple and intuitive tally counter app.</p>
          <ul>
            <li>Swipe right or use the Up Arrow key or Space bar to increase the tally</li>
            <li>Swipe left or use the Down Arrow key to decrease the tally</li>
            <li>Press the Escape key to reset the tally</li>
          </ul>
          <button onClick={onStart}>Start Counting</button>
        </div>
      </div>
      <footer className="App-footer">
        <div className="total-count">
          Total: 0
        </div>
        <div className="button-container">
          <button disabled>Up</button>
          <button disabled>Down</button>
          <button disabled className="reset-button">Reset</button>
        </div>
        <div className="credit">
          Created by <a href="https://pixolomew.digital/" target="_blank" rel="noopener noreferrer">Pixolomew.digital</a>
        </div>
      </footer>
    </div>
  );
}

export default Introduction;
